<template>
  <div class="add-new-variable">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('variable.create')"
      :title="$t('variable.create')"
      @submitPressed="submitPressed"
    />
    <variables-form
      v-if="variable"
      :variableObject="variable"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import VariablesForm from "./components/VariablesForm.vue";

export default {
  components: {
    TitleBar,
    VariablesForm,
  },
  data() {
    return {
      variable: {
          name: '',
          description: '',
          tags: []
      },
      action: null,
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
