<template>
  <b-card>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('variable.name')"
            :class="{ error: v$.variable.name.$errors.length }"
          >
            <b-form-input v-model="variable.name" />
            <div
              class="input-errors"
              v-for="error of v$.variable.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group :label="$t('variable.description')">
            <tinymce
              :content="variable.description"
              @editorContentChanged="updateContent"
            />
          </b-form-group>

          <b-form-group :label="$t('forms.tags')">
            <v-select taggable multiple push-tags v-model="variable.tags" :options="variable.tags" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    tinymce,
    vSelect
  },
  props: {
    variableObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      variable: {
        name: { required: this.$translateError('required') },
      },
    };
  },
  created() {},
  data() {
    return {
      variable: this.variableObject,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    updateContent(content) {
      this.variable.description = content;
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.variable.name,
          description: this.variable.description,
          tags: this.variable.tags,
        };
        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Variables,
                body,
                this.$t("variable.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Variables,
                this.variable.id,
                body,
                this.$t("variable.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/variables`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
